import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import LoadingSpinner from '../common/LoadingSpinner';

const NoMatch = (): JSX.Element => {
  const [loading, setLoading] = useState(true);

  // This is to ensure page does not show while loading user access rights
  // Set loading to false after 2s
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className='no-match column center'>
      <h2>{decodeURI(window.location.href)}</h2>
      <h1>
        <FormattedMessage id='text_404' />
      </h1>
      <Link to='/'>
        <button>
          <FormattedMessage id='text_return_to_frontpage' />
        </button>
      </Link>
    </div>
  );
};

export default NoMatch;
