import { useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { b2cPolicies } from '../msal-b2c/msalConfig';

import { Account } from '../types/common';

// Includes GET, POST, PUT and DELETE functions + loading state handling
export const useFetch = () => {
  const [loading, setLoading] = useState(false);

  // Pending requests
  // When request is made it's url is added to pending and removed after request is done
  const pending = useRef<string[]>([]);

  const { instance, accounts } = useMsal();

  const logout = () => {
    localStorage.clear();
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  // Get authorization token
  const aquireToken = async () => {
    const accessTokenRequest = {
      scopes: [b2cPolicies.accessToken],
      account: accounts[0],
    };

    try {
      // If user id is empty logout user
      const user = accounts[0] as Account;
      if (user && user.idTokenClaims.extension_UserId === '') throw Error('');

      let accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

      return accessTokenResponse.accessToken;
    } catch (err) {
      logout();
    }
  };

  // Disable loading if no pending requests
  const disableLoading = (url: string) => {
    if (pending.current.length === 1) setLoading(false);
    let pendingCopy: string[] = [...pending.current];
    pendingCopy.splice(
      pendingCopy.findIndex((e) => e === url),
      1
    );
    pending.current = pendingCopy;
  };

  const doFetch = async <T,>(url: string, body?: T, method = 'GET', message?: string) => {
    if (method === 'GET') {
      if (!loading) setLoading(true);
      pending.current = [...pending.current, url];
    }

    const token = await aquireToken();
    try {
      const result = await fetch(url, {
        method: method,
        body: body ? JSON.stringify(body) : null,
        headers: new Headers({
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      });
      if (result.ok) {
        const contentType = result.headers.get('content-type');
        if (method !== 'GET' && message) toast.success(message);
        if (contentType && contentType.indexOf('application/json') !== -1) {
          const json = await result.json();
          return json;
        }
        return result;
      } else {
        toast.error('Request error: ' + result.statusText);
      }
    } catch (error) {
      toast.error('Error: ' + error);
    } finally {
      if (method === 'GET') disableLoading(url);
    }
  };

  const doForm = async <T,>(url: string, body?: T, message?: string): Promise<any> => {
    if (!loading) setLoading(true);
    pending.current = [...pending.current, url];

    const token = await aquireToken();

    try {
      const result = await fetch(url, {
        method: 'POST',
        body: body as BodyInit | null | undefined,
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      });
      if (result.ok) {
        const contentType = result.headers.get('content-type');
        if (message) toast.success(message);
        if (contentType && contentType.indexOf('application/json') !== -1) {
          const json = await result.json();
          return json;
        }
        return result;
      } else {
        const errorText = await result.text();
        const errorDetails = errorText ? ` (${errorText})` : '';
        toast.error('Request error: ' + result.statusText + errorDetails);
      }
    } catch (error) {
      toast.error('Error: ' + error);
    } finally {
      disableLoading(url);
    }
  };

  const doPost = async <T,>(url: string, body?: T, message = '') => {
    return await doFetch(url, body, 'POST', message);
  };

  const doPut = async <T,>(url: string, body?: T, message = '') => {
    return await doFetch(url, body, 'PUT', message);
  };

  const doDelete = async <T,>(url: string, body?: T, message = '') => {
    return await doFetch(url, body, 'DELETE', message);
  };

  return {
    GET: doFetch,
    POST: doPost,
    FORM: doForm,
    PUT: doPut,
    DELETE: doDelete,
    loading: loading,
  };
};
