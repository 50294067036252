import isValid from 'date-fns/isValid';
import format from 'date-fns/format';

import { patterns, statusColors } from '../constants/constants';
import { ValidationPattern } from '../types/commonTypes';
import { subDays, subHours } from 'date-fns';
import { RangeType } from './Calendar';

export function formatTimestamp(date: Date | string | number | undefined | null): string {
  if (date === undefined || date === null) return '';
  date = new Date(date);

  if (isValid(date)) {
    return format(date, 'dd.MM.y HH.mm.ss');
  }

  return '';
}

export function formatNumber(number: number | string, decimals: number) {
  if (isNaN(Number(number))) return number;
  return Number(number).toFixed(decimals);
}

/**
 * Green: data updated within 24h,
 * Yellow: data updated between last 24 & 72 hours,
 * Red: data not updated in over 72 hours
 */
export function getStatusColor(timestamp: string | number): string {
  const date = new Date(timestamp);

  if (!isValid(date)) return statusColors[2];

  const now = Date.now();
  const difference = now - date.getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const threeDays = 3 * oneDay;

  if (difference < oneDay) {
    return statusColors[0];
  } else if (difference >= oneDay && difference < threeDays) {
    return statusColors[1];
  } else {
    return statusColors[2];
  }
}

export function validate(text: string, pattern: ValidationPattern) {
  return patterns[pattern].test(text);
}

export function setDateWithRangeType(
  setDates: React.Dispatch<React.SetStateAction<[Date, Date]>>,
  rangeType: RangeType
) {
  const start = new Date();
  const now = new Date();
  switch (rangeType) {
    case 'last_day':
      setDates([subHours(start, 24), now]);
      break;
    case 'last_week':
      setDates([subDays(start, 7), now]);
      break;
    case 'last_month':
      setDates([subDays(start, 30), now]);
      break;
    case 'last_hour':
      setDates([subHours(start, 1), now]);
      break;
    case 'last_six_hour':
      setDates([subHours(start, 6), now]);
      break;
    case 'last_twelve_hour':
      setDates([subHours(start, 12), now]);
      break;
  }
}

export function transformCSVToString(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvString = event.target ? event.target.result : '';
      resolve(csvString as string);
    };

    reader.onerror = (event) => {
      console.log('Unable to read csv file', event);
    };

    reader.readAsText(file);
  });
}
