// @ts-nocheck
// TODO: fix typescript types
import { useCallback, useState, useMemo, lazy, Suspense } from 'react';

import { GroupBase } from 'react-select/dist/declarations/src/';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { colors } from '../constants/constants';

import { ValueProp } from '../types/commonTypes';
import LoadingSpinner from './LoadingSpinner';

const ReactSelect = lazy(() => import('react-select'));

const getCustomStyles = <T extends {}, isMulti extends boolean = false>(): StylesConfig<
  ValueProp<T>,
  isMulti,
  GroupBase<ValueProp<T>>
> => ({
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    margin: '0',
    fontSize: '1rem',
    borderRadius: '2px',
    border: `2px solid ${colors.secondary3}`,
    '& svg': {
      display: 'initial !important',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    boxShadow: 'none',
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
    position: 'fixed',
    backgroundColor: 'white',
    border: `2px solid ${colors.secondary3}`,
    width: state.selectProps.width,
    marginLeft: '-2px',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
});

const getLightStyles = <T extends {}, isMulti extends boolean = false>(): StylesConfig<
  ValueProp<T>,
  isMulti,
  GroupBase<ValueProp<T>>
> => ({
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    margin: '0',
    fontSize: '1rem',
    border: 'none',
    borderRadius: '0',
    borderBottom: '1px solid #ffffff',
    borderTop: '1px solid #ffffff',
    '& svg': {
      display: 'initial !important',
    },
  }),
  option: (provided, state) => ({
    ...provided,
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    borderBottom: `1px solid ${colors.secondary3}`,
    borderRadius: '0',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: colors.secondary3,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    '& svg': {
      fill: `${colors.main2} !important`,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.main2,
    padding: '0',
    margin: 0,
    fontWeight: 'bold',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '1.1rem 0 1.1rem 1rem',
  }),
  input: (provided, state) => ({
    ...provided,
    color: colors.main2,
    margin: '0',
  }),
});

interface SelectProps<T> {
  id?: string;
  light?: boolean;
  label?: string;
  options: ValueProp<T>[];
  onChange(option: any): void;
  value?: ValueProp<T> | ValueProp<T>[];
  menuPlacement?: 'bottom' | 'auto' | 'top';
  minMenuHeight?: number;
  maxMenuHeight?: number;
  isDisabled?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  className?: string;
}

const Select = <T extends {}>(props: SelectProps<T>): JSX.Element => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const onRefChange = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) setElement(node);
    },
    [props.options, props.value]
  );

  const width = useMemo(() => {
    if (element !== null && element.clientWidth !== 0) return element.clientWidth + 'px';
    return 'calc(100% - 2rem)';
  }, [element]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div ref={onRefChange} className={props.className || ''}>
        {props.label && <label>{props.label}</label>}
        <ReactSelect<ValueProp<T>>
          id={props.id}
          menuPlacement={props.menuPlacement}
          isMulti={props.isMulti}
          minMenuHeight={props.minMenuHeight}
          maxMenuHeight={props.maxMenuHeight}
          closeMenuOnSelect={props.closeMenuOnSelect}
          styles={props.light ? getLightStyles<T>() : getCustomStyles<T>()}
          width={width}
          {...props}
        />
      </div>
    </Suspense>
  );
};

export default Select;
