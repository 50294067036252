import { endOfDay, startOfDay } from 'date-fns';
import { SWRConfiguration } from 'swr';

export const options: SWRConfiguration = {
  revalidateOnFocus: false,
};

export const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_APIKEY || '';

export const getStartDates = (date?: Date): [Date, Date] => {
  let date1 = startOfDay(date ? new Date(date) : new Date());
  let date2 = endOfDay(date ? new Date(date) : new Date());

  return [date1, date2];
};

export const toUTC = (date: Date) => new Date(date).toUTCString();
