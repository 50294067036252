import { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMsal } from '@azure/msal-react';

import ExpandIcon from '../icons/ExpandIcon';
import UserIcon from '../icons/UserIcon';
import Select from '../common/Select';

import { User, Company, ValueProp } from '../types/commonTypes';
import LanguageBlock from './LanguageBlock';

interface UserSettingsProps {
  user: User;
  changeCompany(company: Company): void;
  changeLocale(locale: string): void;
  profileLinks: JSX.Element[];
}

const UserSettings = (props: UserSettingsProps): JSX.Element => {
  const { user, changeCompany, changeLocale, profileLinks } = props;

  const [open, setOpen] = useState(false);
  const { instance } = useMsal();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', ({ target }) => {
      if (menuRef.current) {
        if (!menuRef.current.contains(target as Node)) setOpen(false);
      }
    });
    return () => {
      document.removeEventListener('click', ({ target }) => {
        if (menuRef.current) {
          if (!menuRef.current?.contains(target as Node)) setOpen(false);
        }
      });
    };
  }, []);

  const handleLogout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  return (
    <div className='profile-block' ref={menuRef}>
      <div className='profile-info'>
        <div className='profile-info-user pointer' onClick={() => setOpen(!open)}>
          <UserIcon />
          <div>
            <p>{user.userName}</p>
            <p className='small-text'>{user.company.name}</p>
          </div>
        </div>
        <LanguageBlock changeLocale={changeLocale} />
      </div>
      <ExpandIcon className={open ? 'open-menu profile-expand' : 'profile-expand'} />
      <div className={`profile-menu ${open ? '' : 'hide'}`}>
        <Select
          options={user.companies.map((e) => ({ value: e.id, label: e.name }))}
          onChange={(e: ValueProp) => changeCompany({ id: e.value, name: e.label })}
          value={{ value: user.company.id, label: user.company.name }}
          light={true}
        />
        <div className='profile-link'>
          {profileLinks}
          <a onClick={() => handleLogout()}>
            <FormattedMessage id='button_logout' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
