import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInitialPage } from './hooks';

const LinkRedirect: React.FC = () => {
  const [page, removePage] = useInitialPage();
  const navigate = useNavigate();

  useEffect(() => {
    if (page && window.location.pathname !== page) navigate(page);
    removePage();
  }, []);

  return null;
};

export default LinkRedirect;
