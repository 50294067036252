const baseUrl = process.env.REACT_APP_API_ROOT;

export const apiUrls = {
  company: `${baseUrl}/Company`,
  userChangeCompany: `${baseUrl}/User/company`,
  devices: `${baseUrl}/Device/DeviceType`,
  eventLocationData: `${baseUrl}/Event?eventType=location`,
  eventEnvironmentData: `${baseUrl}/Event?eventType=environment`,
  eventSmokeDetectorData: `${baseUrl}/Event?eventType=smokedetector`,
  eventWaterData: `${baseUrl}/TimeseriesData?eventType=water`,
  eventsById: `${baseUrl}/Event`,
  timeseries: `${baseUrl}/TimeseriesData`,
  deviceGroups: `${baseUrl}/DeviceGroup`,
  permissionGroups: `${baseUrl}/PermissionGroup`,
  companyUsers: `${baseUrl}/Company/Users`,
  invite: `${baseUrl}/Invite`,
  companyMember: `${baseUrl}/CompanyMember`,
  user: `${baseUrl}/User`,
  devicePermission: `${baseUrl}/DevicePermission`,
  device: `${baseUrl}/Device`,
  apikey: `${baseUrl}/APIKey`,
  alert: `${baseUrl}/Alert`,
  alertRules: `${baseUrl}/AlertRule`,
  deviceModel: `${baseUrl}/DeviceModel`,
  deviceType: `${baseUrl}/DeviceType`,
  dataPlans: `${baseUrl}/Company/dataplans`,
  orderSims: `${baseUrl}/Company/ordersims`,
  alertMember: `${baseUrl}/AlertMember`,
  geofence: `${baseUrl}/DeviceGeofence`,
  notification: `${baseUrl}/Notification`,
  dataOrder: `${baseUrl}/DataOrder`,
  dataField: `${baseUrl}/DataField`,
};
