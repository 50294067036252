import { IDataComparison } from '../types/managementTypes';

export const colors = {
  main1: '#00239d',
  main2: '#40404a',
  main3: '#ffffff',
  secondary1: '#006aed',
  secondary2: '#8d8d95',
  secondary3: '#d2d2d6',
  secondary4: '#f6f6f7',
  secondary5: '#00a860',
  secondary6: '#e0022a',
  secondary7: '#ffaf38',
  secondary8: '#fafafb',
  secondary9: '#ffb9b5',
  secondary10: '#deedff',
};

export const Animations = {
  PAGE_TRANSITION_DURATION: 450,
  MODAL_DELAY: 300,
};

/**
 * `1` = Green: data updated within 24h,
 * `2` = Yellow: data updated between last 24 & 72 hours,
 * `3` = Red: data not updated in over 72 hours
 */
export const statusColors = [colors.secondary5, colors.secondary7, colors.secondary6];

export const nullGuid = '00000000-0000-0000-0000-000000000000';

export const patterns = {
  SMS: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]{7,13}$/,
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  WEBHOOK: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  JSON_KEY: /^[a-zA-Z0-9_]+$/,
};

// Google maps
export const defaultMapCenter = { lat: 60.19878663, lng: 24.93325233 };
export const defaultMapZoom = 6;
export const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_APIKEY || '';

// Alert rules
export const dataComparisons: IDataComparison[] = [
  {
    dataType: 'Numerical',
    validComparisonTypes: [0, 1, 2, 3, 4],
    jsonFieldAmount: 1,
  },
  {
    dataType: 'Distance',
    validComparisonTypes: [0, 1, 2, 3, 4],
    jsonFieldAmount: 2,
  },
  {
    dataType: 'String',
    validComparisonTypes: [2, 3],
    jsonFieldAmount: 1,
  },
  {
    dataType: 'Boolean',
    validComparisonTypes: [2, 3],
    jsonFieldAmount: 1,
  },
  {
    dataType: 'Inactivity',
    validComparisonTypes: [],
    jsonFieldAmount: 0,
  },
];

export const emptyChart = {
  labels: [],
  datasets: [],
};
