import { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Bounds } from 'google-map-react';
import { useIntl } from 'react-intl';
import { SWRConfiguration } from 'swr';

import { alertTypes, comparisonTypes, dataFieldTypes, stringValues, StringValues, types } from '../translations/units';
import { formatNumber, formatTimestamp, setDateWithRangeType, validate } from './helpers';
import { options } from '../../helpers/common';
import { RangeType } from './Calendar';
import { BoundsArray, Maps, GeojsonPoint } from '../types/googleMapTypes';
import { ValidationPattern, ValueProp } from '../types/commonTypes';
import { ILocalisation } from '../types/managementTypes';

export const useGoogleMaps = () => {
  const [bounds, setBounds] = useState<BoundsArray | null>(null);
  const [zoom, setZoom] = useState(15);
  const [mapLoaded, setMapLoaded] = useState(false);

  const mapRef = useRef<google.maps.Map | null>(null);
  const mapsRef = useRef<typeof google.maps | null>(null);

  const updateMapValues = ({ zoom, bounds }: { zoom: number; bounds: Bounds }) => {
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
    setZoom(zoom);
  };

  // Update both map and maps ref
  const onGoogleApiLoaded = ({ map, maps }: Maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    setMapLoaded(true);
  };

  const fitBounds = (points: GeojsonPoint[]) => {
    if (mapLoaded && mapsRef.current && mapRef.current) {
      let bounds = new mapsRef.current.LatLngBounds();
      points.forEach((point) => {
        const [longitude, latitude] = point.geometry.coordinates;
        if (mapsRef.current) {
          const latlng = new mapsRef.current.LatLng(latitude, longitude);
          bounds.extend(latlng);
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  };

  return {
    mapRef: mapRef,
    mapsRef: mapsRef,
    bounds: bounds,
    zoom: zoom,
    mapLoaded: mapLoaded,
    fitBounds: fitBounds,
    updateMapValues: updateMapValues,
    onGoogleApiLoaded: onGoogleApiLoaded,
  };
};

/**
 * Return memoized values for Select components options prop
 */
export const useValueProp = <T extends { [key: string]: any }>(data: T[], labelKey: keyof T, valueKey: keyof T) => {
  const values = useMemo((): ValueProp[] => {
    return data.map((row) => ({ label: row[labelKey], value: row[valueKey] }));
  }, [data]);

  return values;
};

export const useFormattedMessage = () => {
  const intl = useIntl();
  return (id: string) => intl.formatMessage({ id: id });
};

export type locales = keyof ILocalisation;

export const useLocale = () => {
  const [locale, setLocale] = useState<locales>('fi');

  useEffect(() => {
    const loc = localStorage.getItem('locale');
    if (loc !== null && locale !== loc) {
      setLocale(loc as locales);
    }
  });

  const changeLocale = (loc: locales) => {
    localStorage.setItem('locale', loc);
    setLocale(loc);
  };

  return {
    locale,
    changeLocale,
  };
};

export function useFormatString() {
  const msg = useFormattedMessage();

  const formatString = useCallback(
    (value: string | number | undefined, key: keyof StringValues) => {
      if (value === undefined) return '';
      value = Number(value);
      const valueString = stringValues[key][value];
      return valueString ? msg(valueString) : '';
    },
    [msg]
  );

  return formatString;
}

export const useFormattedValue = () => {
  const formatString = useFormatString();

  const formatValue = useCallback((property: string, value: number | string): number | string => {
    if (value === null) return 'N/A';
    if (types.date.includes(property)) return formatTimestamp(value);
    if (types.float.includes(property)) return formatNumber(value, 2);
    if (types.int.includes(property)) return formatNumber(value, 0);
    if (types.string.includes(property)) return formatString(value, property as keyof StringValues);
    if (value !== undefined || value !== '') {
      return value;
    }
    return '';
  }, []);

  return formatValue;
};

export const useDataTypes = () => {
  const msg = useFormattedMessage();

  const types = useMemo(() => dataFieldTypes.map((dType, index) => ({ value: index, label: msg(dType) })), [msg]);

  return types;
};

export const useAlertRuleTypes = () => {
  const msg = useFormattedMessage();
  const types: ValueProp<number>[] = useMemo(
    () => alertTypes.map((aType, index) => ({ value: index, label: msg(aType) })),
    [msg]
  );
  const fieldTypes: ValueProp<number>[] = useMemo(
    () => dataFieldTypes.map((fType, index) => ({ value: index, label: msg(fType) })),
    [msg]
  );

  const compTypes: ValueProp<number>[] = useMemo(
    () => comparisonTypes.map((cType, index) => ({ value: index, label: msg(cType) })),
    [msg]
  );

  return {
    alertTypes: types,
    dataFieldTypes: fieldTypes,
    comparisonTypes: compTypes,
  };
};

export const useDateRange = (setDates: React.Dispatch<React.SetStateAction<[Date, Date]>>) => {
  const [rangeType, setRangeType] = useState<RangeType>('last_day');

  useEffect(() => setDateWithRangeType(setDates, rangeType), [rangeType]);

  return {
    rangeType,
    setRangeType,
  };
};

export const useAutoRefresh = (rangeType: RangeType, setDates: React.Dispatch<React.SetStateAction<[Date, Date]>>) => {
  const [autoRefresh, setAutoRefresh] = useState(false);

  useEffect(() => {
    if (autoRefresh) {
      const updateInterval = setInterval(() => setDateWithRangeType(setDates, rangeType), 60000);
      return () => clearInterval(updateInterval);
    }
  }, [autoRefresh]);

  const refreshOptions: SWRConfiguration = useMemo(
    () => ({
      ...options,
      refreshInterval: autoRefresh ? 30000 : 0,
    }),
    [autoRefresh]
  );

  return {
    autoRefresh: autoRefresh,
    setAutoRefresh: setAutoRefresh,
    refreshOptions: refreshOptions,
  };
};

export const useFilterByKeyword = <T extends {}>(data: T[], fields: (keyof T)[], keyword: string) => {
  const filteredData = useMemo(() => {
    const keywordLowerCase = keyword.toLowerCase();
    return data.filter((row) => {
      for (const key of fields) {
        const value = row[key] as any;
        if (typeof value === 'string' && value.toLowerCase().includes(keywordLowerCase)) return row;
      }
    });
  }, [data, fields, keyword]);

  return filteredData;
};

export const useDeviceSize = () => {
  const isBigScreen = useMediaQuery({ query: '(min-width: 991.99px)' });
  return { isBigScreen };
};

export const useTextValidation = (pattern: ValidationPattern) => {
  const [error, setError] = useState(false);

  const validateInput = (value: string) => {
    if (validate(value, pattern)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return {
    error,
    validateInput,
    reset: () => setError(true),
  };
};

export function useInitialPage(initialValue?: string | Object | undefined) {
  const key = 'page';
  const page = useMemo(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading sessionStorage key "${key}":`, error);
      return initialValue;
    }
  }, []);

  useEffect(() => {
    if (initialValue) {
      try {
        const prevValue = window.sessionStorage.getItem(key);
        if (!prevValue && initialValue !== '/') window.sessionStorage.setItem(key, JSON.stringify(initialValue));
      } catch (error) {
        console.error('Error saving initial value to sessionStorage', error);
      }
    }
  }, []);

  const removePage = () => {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item with key "${key}":`, error);
    }
  };

  return [page, removePage];
}
