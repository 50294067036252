import { useMsal } from '@azure/msal-react';
import { FormattedMessage } from 'react-intl';
import { locales } from '../common/hooks';

interface ILoginPageProps {
  locale: locales;
  changeLocale(loc: locales): void;
}

const LoginPage = ({ locale, changeLocale }: ILoginPageProps): JSX.Element => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect();
  };

  return (
    <div className={'login'}>
      <div className={'login-box center column'}>
        <h1>Elisa Business IoT</h1>
        <div className='row gap1'>
          <span className={`pointer ${locale === 'fi' ? 'active' : ''}`} onClick={() => changeLocale('fi')}>
            Suomeksi
          </span>
          |
          <span className={`pointer ${locale === 'en' ? 'active' : ''}`} onClick={() => changeLocale('en')}>
            In English
          </span>
        </div>
        <button onClick={() => handleLogin()} key='loginPopup'>
          <FormattedMessage id='button_login' />
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
